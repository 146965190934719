var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch-container" },
    [
      _vm.field
        ? _c(
            "CSwitch",
            _vm._b(
              {
                attrs: {
                  label: _vm.shownLabel,
                  checked: _vm.checked,
                  isValid: _vm.checkIfValid,
                  id: _vm.safeId(),
                  variant: "3d",
                  color: "primary",
                  size: "sm"
                },
                on: {
                  "update:checked": function(val) {
                    return _vm.updatedChecked(val)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "invalid-feedback",
                      fn: function() {
                        return [
                          _c("ValidatedInputErrors", {
                            attrs: { label: _vm.shownLabel, field: _vm.field }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "prepend-content",
                      fn: function() {
                        return [_vm._t("prepend-content")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              "CSwitch",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
      _vm.shownLabel || _vm.shownDescription
        ? _c(
            "label",
            { staticClass: "switch-label", attrs: { for: _vm.safeId() } },
            [_vm._v(" " + _vm._s(_vm.shownLabel) + " ")]
          )
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "c-tooltip",
              rawName: "v-c-tooltip",
              value: { appendToBody: true, content: _vm.shownDescription },
              expression: "{ appendToBody: true, content: shownDescription }"
            }
          ]
        },
        [
          _vm.shownDescription
            ? _c("CIcon", {
                staticClass: "infoIcon",
                attrs: { name: "cis-info-circle" }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }